import React, {useRef, useState} from 'react';
import Toggle from '../form-element/toggle';
import Pdf from "../../pdf-gen/pdf";
import {useReactToPrint} from "react-to-print";
import {setDataStatic} from "../../../store/slices/dataStatic";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {formatInternationalPhoneNumber} from "../../helpers";
import {setSkipStatus} from "../../../store/slices/observerCheck";
import {setIsSavedSettings} from "../../../store/slices/modalSettings";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";


const Setting = ({ visible, handleData, typeSetting }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {data} = useAppSelector(state => state.dataSlice);
  const {skipStatus} = useAppSelector(state => state.skipStatusSlice);
  const {isSaved} = useAppSelector(state => state.modalSettings);

  const agent = data.agent;
  const phone = agent.phone !== null ? formatInternationalPhoneNumber(agent.phone, 'RU') : null;

  const [slovo, setSlovo] = useState<Boolean>(false);
  const [openSetting, setOpenSetting] = useState<boolean>(false);

  const clickDown = () => {
    slovo === true ? setSlovo(false) : setSlovo(true);
    setOpenSetting(prevState => !prevState);
    handleData();

    dispatch(setSkipStatus(false));
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const copyRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const {scroll} = useAppSelector(state => state.scrollSlice);

  const handlePrint = useReactToPrint({
    suppressErrors: true,
    content: () => componentRef.current,
    onAfterPrint: () => endPdf(),
    print: async () => {
      // window.open('/pdf-loading', '_blank')
    }
  });

  const handleStylePdf =  async () => {
    // handlePrint();
    localStorage.setItem('dataSlice', JSON.stringify(data));
  }

  const endPdf = () => {
    if(!skipStatus) dispatch(setDataStatic(''));
  }

  const copyText = () => {
    if(copyRef){
      copyRef.current?.select();
      document.execCommand("copy");
      if (window.getSelection) window.getSelection()?.removeAllRanges();
    }
  }

    if (visible) {
    return null;
  } else {
    return (
        <>
          <div className={'share-desktop flex flex-col fixed bottom-4 right-4 z-[100] rounded-2xl'}>
            {
              // openSetting ?
              //     <div className={'p-[30px] bg-[#FAFAFA] flex gap-[10px] content-center justify-between rounded-t-2xl'} id="headSetting">
              //       <p className={'text-[#151515] text-[20px] text-center w-full'}>Настройка презентации</p>
              //       <div className={'text-[18px] font-[600] cursor-pointer flex items-center gap-[8px]'}>
              //         <div className={'flex flex-row items-center cursor-pointer hover:text-btn-txt'}
              //              onClick={() => {
              //                setOpenSetting(!openSetting);
              //                dispatch(setIsSavedSettings(isSaved));
              //              }}>
              //           <span>{openSetting ? 'Скрыть' : 'Раскрыть'}</span>
              //           <svg className={'ml-[8px]'} width="16px" fill="currentColor" height="16px" viewBox="0 0 16 16">
              //             <path fillRule="evenodd" clipRule="evenodd" d="M4.4824 5.6446L4.48406 5.64687L4.48991 5.65474L4.51372 5.6868C4.53484 5.71514 4.56622 5.75707 4.60671 5.81067C4.6877 5.91787 4.80496 6.0716 4.94907 6.25627C5.2378 6.62627 5.63206 7.11754 6.05708 7.60707C6.4847 8.09954 6.93168 8.5766 7.32688 8.92574C7.52521 9.101 7.69634 9.23134 7.83454 9.31467C7.92474 9.369 7.97774 9.38914 7.99934 9.39647C8.02088 9.38914 8.07388 9.369 8.16408 9.31467C8.30228 9.23134 8.47341 9.101 8.67174 8.9258C9.06694 8.5766 9.51394 8.09954 9.94154 7.60707C10.3665 7.11754 10.7608 6.6262 11.0495 6.25627C11.1936 6.0716 11.3109 5.91787 11.3919 5.81067C11.4323 5.75707 11.4637 5.71514 11.4849 5.6868L11.5087 5.65474L11.5159 5.645C11.7123 5.37814 12.0883 5.32074 12.3551 5.51727C12.6219 5.7138 12.6789 6.0894 12.4824 6.3562L11.9993 6.0004C12.4824 6.3562 12.4825 6.35614 12.4824 6.3562L12.4798 6.35974L12.4729 6.369L12.447 6.40387C12.4245 6.43414 12.3915 6.47814 12.3494 6.53387C12.2652 6.6454 12.1441 6.8042 11.9955 6.99454C11.6989 7.37454 11.2909 7.88327 10.8476 8.39374C10.407 8.90127 9.91988 9.4242 9.46628 9.825C9.24014 10.0248 9.00821 10.2069 8.78361 10.3424C8.57541 10.4679 8.29814 10.6004 7.99934 10.6004C7.70048 10.6004 7.42321 10.4679 7.21501 10.3424C6.99041 10.2069 6.75848 10.0248 6.53235 9.825C6.07874 9.4242 5.59161 8.90127 5.15097 8.3938C4.70775 7.88327 4.29964 7.3746 4.00306 6.99454C3.85452 6.8042 3.7334 6.64547 3.64916 6.53394C3.60703 6.47814 3.5741 6.43414 3.55154 6.40387L3.52564 6.369L3.5188 6.3598L3.51643 6.3566C3.51638 6.35654 3.51618 6.3562 3.96226 6.02767L3.51643 6.3566C3.31992 6.08974 3.37666 5.7138 3.64348 5.51734C3.91028 5.3208 4.28589 5.3778 4.4824 5.6446Z"></path>
              //           </svg>
              //         </div>
              //       </div>
              //     </div> : null
            }
            {
              openSetting ?
                  <div id="bodySetting">
                      <div className={'flex flex-col gap-[15px] p-[30px] bg-[#FFFFFF] '} id="inputsSetting">
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"agregatorData"}/>
                              <p className={'type-setting-modal'} id="agregatorData">Данные агентства</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"agentData"}/>
                              <p className={'type-setting-modal'} id="agentData">Данные агента</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"complexName"}/>
                              <p className={'type-setting-modal'} id="complexName">Название комплекса</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"hcDesc"}/>
                              <p className={'type-setting-modal'} id="hcDesc">Описание ЖК</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"preferences"}/>
                              <p className={'type-setting-modal'} id="preferences">Преимущества</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"aboutHome"}/>
                              <p className={'type-setting-modal'} id="aboutHome">Характеристики дома</p>
                          </div>
                          <div className={'flex gap-[15px]'}>
                              <Toggle typeName={"documents"}/>
                              <p className={'type-setting-modal'} id="documents">Документы</p>
                          </div>
                      </div>
                      <div className={'border-y-[1px] bg-[#FFFFFF]'} id="buttonSave">
                          <div className={'p-[30px]'}>
                              <button className={'w-full text-center bg-[#333333] text-white rounded-xl text-[16px] p-[16px]'} onClick={() => {
                                dispatch(setIsSavedSettings(true))
                              }}>
                                Сохранить настройки
                              </button>
                          </div>
                      </div>
                  </div> : null
            }
            {
              !openSetting ?
                  <div className={'p-[30px] bg-[#FAFAFA] flex gap-[10px] content-center items-center justify-between rounded-t-2xl'} id="headSetting">
                    <p className={'text-[#151515] text-[20px] text-center w-full'}>Настройка презентации</p>
                    {/*<div className={'text-[18px] font-[600] cursor-pointer flex items-center gap-[8px]'}>*/}
                    {/*  <div className={'flex flex-row items-center cursor-pointer hover:text-btn-txt'}*/}
                    {/*       onClick={() => {*/}
                    {/*         setOpenSetting(!openSetting);*/}
                    {/*         dispatch(setIsSavedSettings(false));*/}
                    {/*       }}>*/}
                    {/*    <span>{openSetting ? 'Скрыть' : 'Раскрыть'}</span>*/}
                    {/*    <svg className={'ml-[8px] rotate-180'} width="16px" fill="currentColor" height="16px" viewBox="0 0 16 16">*/}
                    {/*      <path fillRule="evenodd" clipRule="evenodd" d="M4.4824 5.6446L4.48406 5.64687L4.48991 5.65474L4.51372 5.6868C4.53484 5.71514 4.56622 5.75707 4.60671 5.81067C4.6877 5.91787 4.80496 6.0716 4.94907 6.25627C5.2378 6.62627 5.63206 7.11754 6.05708 7.60707C6.4847 8.09954 6.93168 8.5766 7.32688 8.92574C7.52521 9.101 7.69634 9.23134 7.83454 9.31467C7.92474 9.369 7.97774 9.38914 7.99934 9.39647C8.02088 9.38914 8.07388 9.369 8.16408 9.31467C8.30228 9.23134 8.47341 9.101 8.67174 8.9258C9.06694 8.5766 9.51394 8.09954 9.94154 7.60707C10.3665 7.11754 10.7608 6.6262 11.0495 6.25627C11.1936 6.0716 11.3109 5.91787 11.3919 5.81067C11.4323 5.75707 11.4637 5.71514 11.4849 5.6868L11.5087 5.65474L11.5159 5.645C11.7123 5.37814 12.0883 5.32074 12.3551 5.51727C12.6219 5.7138 12.6789 6.0894 12.4824 6.3562L11.9993 6.0004C12.4824 6.3562 12.4825 6.35614 12.4824 6.3562L12.4798 6.35974L12.4729 6.369L12.447 6.40387C12.4245 6.43414 12.3915 6.47814 12.3494 6.53387C12.2652 6.6454 12.1441 6.8042 11.9955 6.99454C11.6989 7.37454 11.2909 7.88327 10.8476 8.39374C10.407 8.90127 9.91988 9.4242 9.46628 9.825C9.24014 10.0248 9.00821 10.2069 8.78361 10.3424C8.57541 10.4679 8.29814 10.6004 7.99934 10.6004C7.70048 10.6004 7.42321 10.4679 7.21501 10.3424C6.99041 10.2069 6.75848 10.0248 6.53235 9.825C6.07874 9.4242 5.59161 8.90127 5.15097 8.3938C4.70775 7.88327 4.29964 7.3746 4.00306 6.99454C3.85452 6.8042 3.7334 6.64547 3.64916 6.53394C3.60703 6.47814 3.5741 6.43414 3.55154 6.40387L3.52564 6.369L3.5188 6.3598L3.51643 6.3566C3.51638 6.35654 3.51618 6.3562 3.96226 6.02767L3.51643 6.3566C3.31992 6.08974 3.37666 5.7138 3.64348 5.51734C3.91028 5.3208 4.28589 5.3778 4.4824 5.6446Z"></path>*/}
                    {/*    </svg>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <svg onClick={() => {
                      clickDown();
                    }}
                         className={openSetting ? 'spoiler-span rotate-180 cursor-pointer' : 'spoiler-span rotate-0 cursor-pointer'} width="24" height="24" viewBox="0 0 24 24" fill="#151515" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M6.72458 8.46666L6.72707 8.47003L6.73584 8.48188L6.77156 8.52994C6.80323 8.57244 6.85031 8.63535 6.91104 8.71574C7.03252 8.87658 7.20841 9.10715 7.42458 9.38415C7.85768 9.93911 8.44907 10.676 9.0866 11.4103C9.72802 12.149 10.3985 12.8646 10.9913 13.3883C11.2888 13.6512 11.5455 13.8467 11.7528 13.9717C11.8881 14.0532 11.9676 14.0834 12 14.0944C12.0323 14.0834 12.1118 14.0532 12.2471 13.9717C12.4544 13.8467 12.7111 13.6512 13.0086 13.3884C13.6014 12.8646 14.2719 12.149 14.9133 11.4103C15.5508 10.676 16.1422 9.93909 16.5752 9.38413C16.7914 9.10712 16.9673 8.87655 17.0888 8.71571C17.1495 8.63532 17.1966 8.57241 17.2283 8.52991L17.264 8.48184L17.2748 8.46723C17.5695 8.067 18.1334 7.98089 18.5336 8.27565C18.9338 8.57041 19.0193 9.13381 18.7246 9.53403L17.9999 9.00033C18.7246 9.53403 18.7247 9.53393 18.7246 9.53403L18.7207 9.53936L18.7104 9.55324L18.6715 9.60552C18.6377 9.65091 18.5883 9.71691 18.5251 9.80057C18.3988 9.96786 18.2171 10.206 17.9943 10.4915C17.5494 11.0615 16.9373 11.8246 16.2724 12.5903C15.6115 13.3516 14.8808 14.136 14.2004 14.7372C13.8612 15.0369 13.5133 15.3101 13.1764 15.5133C12.8641 15.7015 12.4482 15.9003 12 15.9003C11.5517 15.9003 11.1358 15.7015 10.8235 15.5133C10.4866 15.3101 10.1387 15.0369 9.7995 14.7372C9.11908 14.136 8.38839 13.3516 7.72743 12.5904C7.0626 11.8246 6.45043 11.0616 6.00557 10.4915C5.78276 10.206 5.60107 9.96791 5.47471 9.80063C5.41152 9.71696 5.36212 9.65097 5.32829 9.60558L5.28944 9.5533L5.27917 9.53942L5.27562 9.53461C5.27555 9.53451 5.27524 9.5341 5.94437 9.04129L5.27562 9.53461C4.98086 9.13439 5.06597 8.57047 5.46619 8.27571C5.8664 7.98096 6.42981 8.06648 6.72458 8.46666Z" fill="#151515"/>
                    </svg>
                  </div> : null
            }
            <hr/>
            <div className={'p-[30px] bg-[#FAFAFA] rounded-b-2xl relative'} id="shared">
              <div id={'links'} className={'links flex flex-col'}>
                <div className="links-title mb-[24px] font-bold">
                  Поделиться презентацией:
                </div>
                <div className="links-elements flex flex-row mb-[24px]">
                  <a className="share-element flex flex-row items-center mr-[16px] hover:text-btn-txt"
                     href={agent.phone ? `https://wa.me/${agent.phone}` : '#'}>
                    <img className={'wa-icon mr-[8px]'} src="/img/wa.svg" alt="wa-icon"/>
                      WhatsApp
                  </a>
                  <a className="share-element flex flex-row items-center mr-[16px] hover:text-btn-txt"
                     href={agent.phone ? `https://t.me/${agent.phone}` : '#'}>
                    <img className={'tg-icon mr-[8px]'} src="/img/tg.svg" alt="tg-icon"/>
                      Telegram
                  </a>
                  <a className="share-element flex flex-row items-center mr-[16px] hover:text-btn-txt"
                     href={agent.phone ? `https://viber.me/${agent.phone}` : '#'}>
                    <img className={'viber mr-[8px]'} src="/img/viber.svg" alt="viber"/>
                      Viber
                  </a>
                </div>
                <div className="links-copy mb-[24px]">
                  <div className="links-copy__element flex flex-row">
                    <input ref={copyRef} type={"text"} defaultValue={window.location.href}
                           className="links-copy__element-pre text-ellipsis border px-[24px] py-[14px] rounded-[12px] border-[#E6E6E6] bg-[#F2F2F2] mr-[10px] max-w-[258px] w-full">
                    </input>
                    <button onClick={copyText}
                            className="links-copy__element-btn w-full flex flex-row items-center bg-white px-[24px] py-[14px] border rounded-[12px] border-[#E6E6E6] hover:bg-btn-icon hover:text-btn-txt">
                      <svg className={'copy-link svg-link mr-[7px]'} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.83033 7.83033C2.1398 8.52086 1.75 9.67397 1.75 11.6V15.4C1.75 17.326 2.1398 18.4791 2.83033 19.1697C3.52086 19.8602 4.67397 20.25 6.6 20.25H10.4C12.326 20.25 13.4791 19.8602 14.1697 19.1697C14.8602 18.4791 15.25 17.326 15.25 15.4V12.4C15.25 11.9858 15.5858 11.65 16 11.65C16.4142 11.65 16.75 11.9858 16.75 12.4V15.4C16.75 17.474 16.3398 19.1209 15.2303 20.2303C14.1209 21.3398 12.474 21.75 10.4 21.75H6.6C4.52603 21.75 2.87914 21.3398 1.76967 20.2303C0.660201 19.1209 0.25 17.474 0.25 15.4V11.6C0.25 9.52603 0.660201 7.87914 1.76967 6.76967C2.87914 5.6602 4.52603 5.25 6.6 5.25H9.6C10.0142 5.25 10.35 5.58579 10.35 6C10.35 6.41421 10.0142 6.75 9.6 6.75H6.6C4.67397 6.75 3.52086 7.1398 2.83033 7.83033Z" fill="#4D4D4D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.31455 5.30711C9.59481 5.19103 9.91739 5.25519 10.1319 5.46969L16.5319 11.8697C16.7464 12.0842 16.8106 12.4068 16.6945 12.687C16.5784 12.9673 16.3049 13.15 16.0016 13.15H12.8016C11.56 13.15 10.4937 12.9528 9.77123 12.2304C9.04881 11.5079 8.85156 10.4416 8.85156 9.20002V6.00002C8.85156 5.69668 9.03429 5.4232 9.31455 5.30711ZM10.3516 7.81068V9.20002C10.3516 10.3585 10.5543 10.8921 10.8319 11.1697C11.1095 11.4473 11.6431 11.65 12.8016 11.65H14.1909L10.3516 7.81068Z" fill="#4D4D4D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M9.85156 1C9.85156 0.585786 10.1873 0.25 10.6016 0.25H14.6016C15.0158 0.25 15.3516 0.585786 15.3516 1C15.3516 1.41421 15.0158 1.75 14.6016 1.75H10.6016C10.1873 1.75 9.85156 1.41421 9.85156 1Z" fill="#4D4D4D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M9 1.75C7.75421 1.75 6.75 2.75421 6.75 4C6.75 4.41421 6.41421 4.75 6 4.75C5.58579 4.75 5.25 4.41421 5.25 4C5.25 1.92579 6.92579 0.25 9 0.25H11.62C12.0342 0.25 12.37 0.585786 12.37 1C12.37 1.41421 12.0342 1.75 11.62 1.75H9Z" fill="#4D4D4D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M21.0014 6.25C21.4156 6.25 21.7514 6.58579 21.7514 7V13.19C21.7514 15.1542 20.1556 16.75 18.1914 16.75C17.7772 16.75 17.4414 16.4142 17.4414 16C17.4414 15.5858 17.7772 15.25 18.1914 15.25C19.3272 15.25 20.2514 14.3258 20.2514 13.19V7C20.2514 6.58579 20.5872 6.25 21.0014 6.25Z" fill="#4D4D4D"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M14.713 0.307112C14.9932 0.191027 15.3158 0.255193 15.5303 0.469692L21.5303 6.46969C21.7448 6.68419 21.809 7.00678 21.6929 7.28703C21.5768 7.56729 21.3033 7.75002 21 7.75002H18C16.8335 7.75002 15.8171 7.56527 15.1259 6.8741C14.4347 6.18293 14.25 5.16657 14.25 4.00002V1.00002C14.25 0.696675 14.4327 0.423198 14.713 0.307112ZM15.75 2.81068V4.00002C15.75 5.08347 15.9403 5.56711 16.1866 5.81344C16.4329 6.05977 16.9165 6.25002 18 6.25002H19.1893L15.75 2.81068Z" fill="#4D4D4D"/>
                      </svg>
                      <span className="links-copy__element-text whitespace-nowrap">Копировать ссылку</span>
                    </button>
                  </div>
                </div>
              </div>
              <hr/>
              <div className={'flex flex-row mt-[24px]'}>
                <Link to={`/pdf-loading/${window.location.search}`}
                      target="_blank"
                      onClick={handleStylePdf}
                      className={'bg-white px-[24px] py-[12px] rounded-xl border-[1px] border-[#E6E6E6] mr-[10px] flex flex-row items-center hover:bg-btn-icon hover:text-btn-txt'}>
                  <svg className={'download-link svg-link mr-[6px]'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.18934 2.68934C4.47064 2.40804 4.85217 2.25 5.25 2.25H14.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L20.0303 7.71967C20.171 7.86032 20.25 8.05109 20.25 8.25V12C20.25 12.4142 19.9142 12.75 19.5 12.75C19.0858 12.75 18.75 12.4142 18.75 12V8.56066L13.9393 3.75L5.25 3.75L5.25 12C5.25 12.4142 4.91421 12.75 4.5 12.75C4.08579 12.75 3.75 12.4142 3.75 12V3.75C3.75 3.35217 3.90804 2.97064 4.18934 2.68934Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.25 2.25C14.6642 2.25 15 2.58579 15 3V7.5H19.5C19.9142 7.5 20.25 7.83579 20.25 8.25C20.25 8.66421 19.9142 9 19.5 9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V3C13.5 2.58579 13.8358 2.25 14.25 2.25Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M3.75 15.75C3.75 15.3358 4.08579 15 4.5 15H6C6.59674 15 7.16903 15.2371 7.59099 15.659C8.01295 16.081 8.25 16.6533 8.25 17.25C8.25 17.8467 8.01295 18.419 7.59099 18.841C7.16903 19.2629 6.59674 19.5 6 19.5H5.25V20.25C5.25 20.6642 4.91421 21 4.5 21C4.08579 21 3.75 20.6642 3.75 20.25V15.75ZM5.25 18H6C6.19891 18 6.38968 17.921 6.53033 17.7803C6.67098 17.6397 6.75 17.4489 6.75 17.25C6.75 17.0511 6.67098 16.8603 6.53033 16.7197C6.38968 16.579 6.19891 16.5 6 16.5H5.25V18Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.875 15.75C16.875 15.3358 17.2108 15 17.625 15H20.25C20.6642 15 21 15.3358 21 15.75C21 16.1642 20.6642 16.5 20.25 16.5H18.375V20.25C18.375 20.6642 18.0392 21 17.625 21C17.2108 21 16.875 20.6642 16.875 20.25V15.75Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.875 18.375C16.875 17.9608 17.2108 17.625 17.625 17.625H19.875C20.2892 17.625 20.625 17.9608 20.625 18.375C20.625 18.7892 20.2892 19.125 19.875 19.125H17.625C17.2108 19.125 16.875 18.7892 16.875 18.375Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.9375 15.75C9.9375 15.3358 10.2733 15 10.6875 15H12C12.7956 15 13.5587 15.3161 14.1213 15.8787C14.6839 16.4413 15 17.2044 15 18C15 18.7956 14.6839 19.5587 14.1213 20.1213C13.5587 20.6839 12.7956 21 12 21H10.6875C10.2733 21 9.9375 20.6642 9.9375 20.25V15.75ZM11.4375 16.5V19.5H12C12.3978 19.5 12.7794 19.342 13.0607 19.0607C13.342 18.7794 13.5 18.3978 13.5 18C13.5 17.6022 13.342 17.2206 13.0607 16.9393C12.7794 16.658 12.3978 16.5 12 16.5H11.4375Z" fill="#4D4D4D"/>
                  </svg>
                    Скачать
                </Link>
                <button onClick={handleStylePdf}
                        className={'bg-white px-[24px] py-[12px] rounded-xl border-[1px] border-[#E6E6E6] mr-[10px] flex flex-row items-center hover:bg-btn-icon hover:text-btn-txt'}>
                  <svg className={'print-link svg-link mr-[6px]'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.3591 2.23704C8.03327 1.51793 9.03399 1.25 10.25 1.25H13.75C14.966 1.25 15.9667 1.51793 16.6409 2.23704C17.3054 2.94586 17.5 3.93363 17.5 5V7C17.5 7.41421 17.1642 7.75 16.75 7.75H7.25C6.83579 7.75 6.5 7.41421 6.5 7V5C6.5 3.93363 6.69458 2.94586 7.3591 2.23704ZM8.4534 3.26296C8.18042 3.55414 8 4.06637 8 5V6.25H16V5C16 4.06637 15.8196 3.55414 15.5466 3.26296C15.2833 2.98207 14.784 2.75 13.75 2.75H10.25C9.21601 2.75 8.71673 2.98207 8.4534 3.26296Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H16C16.4142 14.25 16.75 14.5858 16.75 15V19C16.75 20.1156 16.4706 21.0901 15.7803 21.7803C15.0901 22.4706 14.1156 22.75 13 22.75H11C9.88439 22.75 8.90993 22.4706 8.21967 21.7803C7.52941 21.0901 7.25 20.1156 7.25 19V15ZM8.75 15.75V19C8.75 19.8844 8.97059 20.4099 9.28033 20.7197C9.59007 21.0294 10.1156 21.25 11 21.25H13C13.8844 21.25 14.4099 21.0294 14.7197 20.7197C15.0294 20.4099 15.25 19.8844 15.25 19V15.75H8.75Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.28033 8.28033C3.97059 8.59007 3.75 9.11561 3.75 10V15C3.75 15.8844 3.97059 16.4099 4.28033 16.7197C4.59007 17.0294 5.11561 17.25 6 17.25H7.25V15C7.25 14.5858 7.58579 14.25 8 14.25H16C16.4142 14.25 16.75 14.5858 16.75 15V17.25H18C18.8844 17.25 19.4099 17.0294 19.7197 16.7197C20.0294 16.4099 20.25 15.8844 20.25 15V10C20.25 9.11561 20.0294 8.59007 19.7197 8.28033C19.4099 7.97059 18.8844 7.75 18 7.75H6C5.11561 7.75 4.59007 7.97059 4.28033 8.28033ZM3.21967 7.21967C3.90993 6.52941 4.88439 6.25 6 6.25H18C19.1156 6.25 20.0901 6.52941 20.7803 7.21967C21.4706 7.90993 21.75 8.88439 21.75 10V15C21.75 16.1156 21.4706 17.0901 20.7803 17.7803C20.0901 18.4706 19.1156 18.75 18 18.75H16C15.5858 18.75 15.25 18.4142 15.25 18V15.75H8.75V18C8.75 18.4142 8.41421 18.75 8 18.75H6C4.88439 18.75 3.90993 18.4706 3.21967 17.7803C2.52941 17.0901 2.25 16.1156 2.25 15V10C2.25 8.88439 2.52941 7.90993 3.21967 7.21967Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.25 15C6.25 14.5858 6.58579 14.25 7 14.25H17C17.4142 14.25 17.75 14.5858 17.75 15C17.75 15.4142 17.4142 15.75 17 15.75H7C6.58579 15.75 6.25 15.4142 6.25 15Z" fill="#4D4D4D"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M6.25 11C6.25 10.5858 6.58579 10.25 7 10.25H10C10.4142 10.25 10.75 10.5858 10.75 11C10.75 11.4142 10.4142 11.75 10 11.75H7C6.58579 11.75 6.25 11.4142 6.25 11Z" fill="#4D4D4D"/>
                  </svg>
                  Печать
                </button>
              </div>
            </div>
          </div>
          <div className={'modal hidden'}>
            {typeSetting === 'setting' ?
                <div className={'modal-container fixed bottom-0 left-0 shadow-2xl overflow-hidden z-50 w-full'}>
                  <div className="modal-content">
                    <div className={'p-[30px]  bg-[#FFFFFF] flex gap-[10px] content-center justify-center items-center rounded-t-[20px]'} id="headSetting">
                      <p className={'text-[#151515] text-[20px] text-center w-full text-[24px]'}>Настройка презентации </p>
                      <svg onClick={() => {
                        clickDown();
                        dispatch(setIsSavedSettings(isSaved));
                        document.body.classList.toggle('modal', false);
                        window.scrollTo({
                          top: scroll,
                          left: 0,
                        });
                      }}
                           className={openSetting ? 'spoiler-span rotate-180 cursor-pointer' : 'spoiler-span rotate-0 cursor-pointer'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M6.72458 8.46666L6.72707 8.47003L6.73584 8.48188L6.77156 8.52994C6.80323 8.57244 6.85031 8.63535 6.91104 8.71574C7.03252 8.87658 7.20841 9.10715 7.42458 9.38415C7.85768 9.93911 8.44907 10.676 9.0866 11.4103C9.72802 12.149 10.3985 12.8646 10.9913 13.3883C11.2888 13.6512 11.5455 13.8467 11.7528 13.9717C11.8881 14.0532 11.9676 14.0834 12 14.0944C12.0323 14.0834 12.1118 14.0532 12.2471 13.9717C12.4544 13.8467 12.7111 13.6512 13.0086 13.3884C13.6014 12.8646 14.2719 12.149 14.9133 11.4103C15.5508 10.676 16.1422 9.93909 16.5752 9.38413C16.7914 9.10712 16.9673 8.87655 17.0888 8.71571C17.1495 8.63532 17.1966 8.57241 17.2283 8.52991L17.264 8.48184L17.2748 8.46723C17.5695 8.067 18.1334 7.98089 18.5336 8.27565C18.9338 8.57041 19.0193 9.13381 18.7246 9.53403L17.9999 9.00033C18.7246 9.53403 18.7247 9.53393 18.7246 9.53403L18.7207 9.53936L18.7104 9.55324L18.6715 9.60552C18.6377 9.65091 18.5883 9.71691 18.5251 9.80057C18.3988 9.96786 18.2171 10.206 17.9943 10.4915C17.5494 11.0615 16.9373 11.8246 16.2724 12.5903C15.6115 13.3516 14.8808 14.136 14.2004 14.7372C13.8612 15.0369 13.5133 15.3101 13.1764 15.5133C12.8641 15.7015 12.4482 15.9003 12 15.9003C11.5517 15.9003 11.1358 15.7015 10.8235 15.5133C10.4866 15.3101 10.1387 15.0369 9.7995 14.7372C9.11908 14.136 8.38839 13.3516 7.72743 12.5904C7.0626 11.8246 6.45043 11.0616 6.00557 10.4915C5.78276 10.206 5.60107 9.96791 5.47471 9.80063C5.41152 9.71696 5.36212 9.65097 5.32829 9.60558L5.28944 9.5533L5.27917 9.53942L5.27562 9.53461C5.27555 9.53451 5.27524 9.5341 5.94437 9.04129L5.27562 9.53461C4.98086 9.13439 5.06597 8.57047 5.46619 8.27571C5.8664 7.98096 6.42981 8.06648 6.72458 8.46666Z"
                              fill={!openSetting ? "#151515" : "#F54012"}/>
                      </svg>
                    </div>
                    <div id="bodySetting">
                      <div className={'flex flex-col gap-[15px] p-[30px] bg-[#FFFFFF] '} id="inputsSetting">
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"agregatorData"}/>
                          <p className={'type-setting-modal'} id="agregatorData">Данные агентства</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"agentData"}/>
                          <p className={'type-setting-modal'} id="agentData">Данные агента</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"complexName"}/>
                          <p className={'type-setting-modal'} id="complexName">Название комплекса</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"hcDesc"}/>
                          <p className={'type-setting-modal'} id="hcDesc">Описание ЖК</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"preferences"}/>
                          <p className={'type-setting-modal'} id="preferences">Преимущества</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"aboutHome"}/>
                          <p className={'type-setting-modal'} id="aboutHome">Характеристики дома</p>
                        </div>
                        <div className={'flex gap-[15px]'}>
                          <Toggle typeName={"documents"}/>
                          <p className={'type-setting-modal'} id="documents">Документы</p>
                        </div>
                      </div>
                      <div className={'border-y-[1px] bg-[#FFFFFF]'} id="buttonSave">
                        <div className={'p-[30px]'}>
                          <button onClick={() => {
                            dispatch(setIsSavedSettings(true));
                          }}
                              className={'w-full text-center bg-[#333333] text-white rounded-xl text-[16px] p-[16px]'}>Сохранить настройки
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={'p-[30px] bg-[#FAFAFA] bg-[#FFFFFF] items-start flex justify-center items-start h-[62px] pt-[10px]'} id="shared">
                      <div id={'links'} className={'flex justify-center items-start h-full'}>
                        Copyright © 2023 Redcat. All rights reserved
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className={'modal-container fixed left-0 bottom-0 shadow-2xl overflow-hidden z-50 w-full'}>
                  <div className="modal-content">
                    <div className={'p-[30px] pb-[12px] bg-[#FFFFFF] flex gap-[10px] content-center justify-center items-center border-b rounded-t-[20px]'} id="headSetting">
                      <p className={'text-[#151515] text-[20px] font-bold w-full text-center text-[24px]'}>Поделиться</p>
                      <svg onClick={() => {
                        clickDown()
                        document.body.classList.toggle('modal', false)
                        window.scrollTo({
                          top: scroll,
                          left: 0,
                        });
                      }}
                           className={openSetting ? 'spoiler-span rotate-180 cursor-pointer' : 'spoiler-span rotate-0 cursor-pointer'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.72458 8.46666L6.72707 8.47003L6.73584 8.48188L6.77156 8.52994C6.80323 8.57244 6.85031 8.63535 6.91104 8.71574C7.03252 8.87658 7.20841 9.10715 7.42458 9.38415C7.85768 9.93911 8.44907 10.676 9.0866 11.4103C9.72802 12.149 10.3985 12.8646 10.9913 13.3883C11.2888 13.6512 11.5455 13.8467 11.7528 13.9717C11.8881 14.0532 11.9676 14.0834 12 14.0944C12.0323 14.0834 12.1118 14.0532 12.2471 13.9717C12.4544 13.8467 12.7111 13.6512 13.0086 13.3884C13.6014 12.8646 14.2719 12.149 14.9133 11.4103C15.5508 10.676 16.1422 9.93909 16.5752 9.38413C16.7914 9.10712 16.9673 8.87655 17.0888 8.71571C17.1495 8.63532 17.1966 8.57241 17.2283 8.52991L17.264 8.48184L17.2748 8.46723C17.5695 8.067 18.1334 7.98089 18.5336 8.27565C18.9338 8.57041 19.0193 9.13381 18.7246 9.53403L17.9999 9.00033C18.7246 9.53403 18.7247 9.53393 18.7246 9.53403L18.7207 9.53936L18.7104 9.55324L18.6715 9.60552C18.6377 9.65091 18.5883 9.71691 18.5251 9.80057C18.3988 9.96786 18.2171 10.206 17.9943 10.4915C17.5494 11.0615 16.9373 11.8246 16.2724 12.5903C15.6115 13.3516 14.8808 14.136 14.2004 14.7372C13.8612 15.0369 13.5133 15.3101 13.1764 15.5133C12.8641 15.7015 12.4482 15.9003 12 15.9003C11.5517 15.9003 11.1358 15.7015 10.8235 15.5133C10.4866 15.3101 10.1387 15.0369 9.7995 14.7372C9.11908 14.136 8.38839 13.3516 7.72743 12.5904C7.0626 11.8246 6.45043 11.0616 6.00557 10.4915C5.78276 10.206 5.60107 9.96791 5.47471 9.80063C5.41152 9.71696 5.36212 9.65097 5.32829 9.60558L5.28944 9.5533L5.27917 9.53942L5.27562 9.53461C5.27555 9.53451 5.27524 9.5341 5.94437 9.04129L5.27562 9.53461C4.98086 9.13439 5.06597 8.57047 5.46619 8.27571C5.8664 7.98096 6.42981 8.06648 6.72458 8.46666Z"
                              fill={!openSetting ? "#151515" : "#F54012"}/>
                      </svg>
                    </div>
                    <div id="bodySetting">
                      <div className="share-elements flex flex-col bg-[#FFFFFF] py-[14px]">
                        <a className="share-element flex flex-row items-center px-[12px] py-[8px] hover:text-btn-txt"
                           href={agent.phone ? `https://wa.me/${agent.phone}` : '#'}>
                          <img className={'wa-icon mr-[8px]'} src="/img/wa.svg" alt="wa-icon"/>
                            WhatsApp
                        </a>
                        <a className="share-element flex flex-row items-center px-[12px] py-[8px] hover:text-btn-txt"
                           href={agent.phone ? `https://t.me/${agent.phone}` : '#'}>
                          <img className={'tg-icon mr-[8px]'} src="/img/tg.svg" alt="tg-icon"/>
                            Telegram
                        </a>
                        <a className="share-element flex flex-row items-center px-[12px] py-[8px] hover:text-btn-txt"
                             href={agent.phone ? `https://viber.me/${agent.phone}` : '#'}>
                          <img className={'viber mr-[8px]'} src="/img/viber.svg" alt="viber"/>
                            Viber
                        </a>
                      </div>
                      <div className={'flex flex-col bg-[#FFFFFF] '} id="inputsSetting">
                        <div className={'flex flex-col'}>
                          <button onClick={() => {
                            navigator.clipboard.writeText(window.location.href)
                          }}
                              className={'bg-white px-[12px] border-t-[1px] border-b-[1px] w-full flex flex-row items-center py-[24px] mb-5 hover:bg-btn-icon hover:text-btn-txt'}>
                            <svg className={'copy-link svg-link mr-[14px]'} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M2.83033 7.83033C2.1398 8.52086 1.75 9.67397 1.75 11.6V15.4C1.75 17.326 2.1398 18.4791 2.83033 19.1697C3.52086 19.8602 4.67397 20.25 6.6 20.25H10.4C12.326 20.25 13.4791 19.8602 14.1697 19.1697C14.8602 18.4791 15.25 17.326 15.25 15.4V12.4C15.25 11.9858 15.5858 11.65 16 11.65C16.4142 11.65 16.75 11.9858 16.75 12.4V15.4C16.75 17.474 16.3398 19.1209 15.2303 20.2303C14.1209 21.3398 12.474 21.75 10.4 21.75H6.6C4.52603 21.75 2.87914 21.3398 1.76967 20.2303C0.660201 19.1209 0.25 17.474 0.25 15.4V11.6C0.25 9.52603 0.660201 7.87914 1.76967 6.76967C2.87914 5.6602 4.52603 5.25 6.6 5.25H9.6C10.0142 5.25 10.35 5.58579 10.35 6C10.35 6.41421 10.0142 6.75 9.6 6.75H6.6C4.67397 6.75 3.52086 7.1398 2.83033 7.83033Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.31455 5.30711C9.59481 5.19103 9.91739 5.25519 10.1319 5.46969L16.5319 11.8697C16.7464 12.0842 16.8106 12.4068 16.6945 12.687C16.5784 12.9673 16.3049 13.15 16.0016 13.15H12.8016C11.56 13.15 10.4937 12.9528 9.77123 12.2304C9.04881 11.5079 8.85156 10.4416 8.85156 9.20002V6.00002C8.85156 5.69668 9.03429 5.4232 9.31455 5.30711ZM10.3516 7.81068V9.20002C10.3516 10.3585 10.5543 10.8921 10.8319 11.1697C11.1095 11.4473 11.6431 11.65 12.8016 11.65H14.1909L10.3516 7.81068Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.85156 1C9.85156 0.585786 10.1873 0.25 10.6016 0.25H14.6016C15.0158 0.25 15.3516 0.585786 15.3516 1C15.3516 1.41421 15.0158 1.75 14.6016 1.75H10.6016C10.1873 1.75 9.85156 1.41421 9.85156 1Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9 1.75C7.75421 1.75 6.75 2.75421 6.75 4C6.75 4.41421 6.41421 4.75 6 4.75C5.58579 4.75 5.25 4.41421 5.25 4C5.25 1.92579 6.92579 0.25 9 0.25H11.62C12.0342 0.25 12.37 0.585786 12.37 1C12.37 1.41421 12.0342 1.75 11.62 1.75H9Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M21.0014 6.25C21.4156 6.25 21.7514 6.58579 21.7514 7V13.19C21.7514 15.1542 20.1556 16.75 18.1914 16.75C17.7772 16.75 17.4414 16.4142 17.4414 16C17.4414 15.5858 17.7772 15.25 18.1914 15.25C19.3272 15.25 20.2514 14.3258 20.2514 13.19V7C20.2514 6.58579 20.5872 6.25 21.0014 6.25Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M14.713 0.307112C14.9932 0.191027 15.3158 0.255193 15.5303 0.469692L21.5303 6.46969C21.7448 6.68419 21.809 7.00678 21.6929 7.28703C21.5768 7.56729 21.3033 7.75002 21 7.75002H18C16.8335 7.75002 15.8171 7.56527 15.1259 6.8741C14.4347 6.18293 14.25 5.16657 14.25 4.00002V1.00002C14.25 0.696675 14.4327 0.423198 14.713 0.307112ZM15.75 2.81068V4.00002C15.75 5.08347 15.9403 5.56711 16.1866 5.81344C16.4329 6.05977 16.9165 6.25002 18 6.25002H19.1893L15.75 2.81068Z" fill="#4D4D4D"/>
                            </svg>
                              Копировать ссылку
                          </button>
                          <Link to={`/pdf-loading/${window.location.search}`} target="_blank"
                                onClick={handleStylePdf}
                                className={'bg-white px-[12px] border-t-[1px] py-[24px]  w-full flex flex-row items-center hover:bg-btn-icon hover:text-btn-txt'}
                          >
                            <svg className={'download-link svg-link mr-[14px]'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.18934 2.68934C4.47064 2.40804 4.85217 2.25 5.25 2.25H14.25C14.4489 2.25 14.6397 2.32902 14.7803 2.46967L20.0303 7.71967C20.171 7.86032 20.25 8.05109 20.25 8.25V12C20.25 12.4142 19.9142 12.75 19.5 12.75C19.0858 12.75 18.75 12.4142 18.75 12V8.56066L13.9393 3.75L5.25 3.75L5.25 12C5.25 12.4142 4.91421 12.75 4.5 12.75C4.08579 12.75 3.75 12.4142 3.75 12V3.75C3.75 3.35217 3.90804 2.97064 4.18934 2.68934Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M14.25 2.25C14.6642 2.25 15 2.58579 15 3V7.5H19.5C19.9142 7.5 20.25 7.83579 20.25 8.25C20.25 8.66421 19.9142 9 19.5 9H14.25C13.8358 9 13.5 8.66421 13.5 8.25V3C13.5 2.58579 13.8358 2.25 14.25 2.25Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M3.75 15.75C3.75 15.3358 4.08579 15 4.5 15H6C6.59674 15 7.16903 15.2371 7.59099 15.659C8.01295 16.081 8.25 16.6533 8.25 17.25C8.25 17.8467 8.01295 18.419 7.59099 18.841C7.16903 19.2629 6.59674 19.5 6 19.5H5.25V20.25C5.25 20.6642 4.91421 21 4.5 21C4.08579 21 3.75 20.6642 3.75 20.25V15.75ZM5.25 18H6C6.19891 18 6.38968 17.921 6.53033 17.7803C6.67098 17.6397 6.75 17.4489 6.75 17.25C6.75 17.0511 6.67098 16.8603 6.53033 16.7197C6.38968 16.579 6.19891 16.5 6 16.5H5.25V18Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M16.875 15.75C16.875 15.3358 17.2108 15 17.625 15H20.25C20.6642 15 21 15.3358 21 15.75C21 16.1642 20.6642 16.5 20.25 16.5H18.375V20.25C18.375 20.6642 18.0392 21 17.625 21C17.2108 21 16.875 20.6642 16.875 20.25V15.75Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M16.875 18.375C16.875 17.9608 17.2108 17.625 17.625 17.625H19.875C20.2892 17.625 20.625 17.9608 20.625 18.375C20.625 18.7892 20.2892 19.125 19.875 19.125H17.625C17.2108 19.125 16.875 18.7892 16.875 18.375Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M9.9375 15.75C9.9375 15.3358 10.2733 15 10.6875 15H12C12.7956 15 13.5587 15.3161 14.1213 15.8787C14.6839 16.4413 15 17.2044 15 18C15 18.7956 14.6839 19.5587 14.1213 20.1213C13.5587 20.6839 12.7956 21 12 21H10.6875C10.2733 21 9.9375 20.6642 9.9375 20.25V15.75ZM11.4375 16.5V19.5H12C12.3978 19.5 12.7794 19.342 13.0607 19.0607C13.342 18.7794 13.5 18.3978 13.5 18C13.5 17.6022 13.342 17.2206 13.0607 16.9393C12.7794 16.658 12.3978 16.5 12 16.5H11.4375Z" fill="#4D4D4D"/>
                            </svg>
                              Скачать
                          </Link>
                          <button onClick={handleStylePdf}
                                  className={'bg-white px-[12px] border-t-[1px] w-full py-[24px] flex flex-row items-center hover:bg-btn-icon hover:text-btn-txt'}>
                            <svg className={'print-link svg-link mr-[14px]'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.3591 2.23704C8.03327 1.51793 9.03399 1.25 10.25 1.25H13.75C14.966 1.25 15.9667 1.51793 16.6409 2.23704C17.3054 2.94586 17.5 3.93363 17.5 5V7C17.5 7.41421 17.1642 7.75 16.75 7.75H7.25C6.83579 7.75 6.5 7.41421 6.5 7V5C6.5 3.93363 6.69458 2.94586 7.3591 2.23704ZM8.4534 3.26296C8.18042 3.55414 8 4.06637 8 5V6.25H16V5C16 4.06637 15.8196 3.55414 15.5466 3.26296C15.2833 2.98207 14.784 2.75 13.75 2.75H10.25C9.21601 2.75 8.71673 2.98207 8.4534 3.26296Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H16C16.4142 14.25 16.75 14.5858 16.75 15V19C16.75 20.1156 16.4706 21.0901 15.7803 21.7803C15.0901 22.4706 14.1156 22.75 13 22.75H11C9.88439 22.75 8.90993 22.4706 8.21967 21.7803C7.52941 21.0901 7.25 20.1156 7.25 19V15ZM8.75 15.75V19C8.75 19.8844 8.97059 20.4099 9.28033 20.7197C9.59007 21.0294 10.1156 21.25 11 21.25H13C13.8844 21.25 14.4099 21.0294 14.7197 20.7197C15.0294 20.4099 15.25 19.8844 15.25 19V15.75H8.75Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M4.28033 8.28033C3.97059 8.59007 3.75 9.11561 3.75 10V15C3.75 15.8844 3.97059 16.4099 4.28033 16.7197C4.59007 17.0294 5.11561 17.25 6 17.25H7.25V15C7.25 14.5858 7.58579 14.25 8 14.25H16C16.4142 14.25 16.75 14.5858 16.75 15V17.25H18C18.8844 17.25 19.4099 17.0294 19.7197 16.7197C20.0294 16.4099 20.25 15.8844 20.25 15V10C20.25 9.11561 20.0294 8.59007 19.7197 8.28033C19.4099 7.97059 18.8844 7.75 18 7.75H6C5.11561 7.75 4.59007 7.97059 4.28033 8.28033ZM3.21967 7.21967C3.90993 6.52941 4.88439 6.25 6 6.25H18C19.1156 6.25 20.0901 6.52941 20.7803 7.21967C21.4706 7.90993 21.75 8.88439 21.75 10V15C21.75 16.1156 21.4706 17.0901 20.7803 17.7803C20.0901 18.4706 19.1156 18.75 18 18.75H16C15.5858 18.75 15.25 18.4142 15.25 18V15.75H8.75V18C8.75 18.4142 8.41421 18.75 8 18.75H6C4.88439 18.75 3.90993 18.4706 3.21967 17.7803C2.52941 17.0901 2.25 16.1156 2.25 15V10C2.25 8.88439 2.52941 7.90993 3.21967 7.21967Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M6.25 15C6.25 14.5858 6.58579 14.25 7 14.25H17C17.4142 14.25 17.75 14.5858 17.75 15C17.75 15.4142 17.4142 15.75 17 15.75H7C6.58579 15.75 6.25 15.4142 6.25 15Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M6.25 11C6.25 10.5858 6.58579 10.25 7 10.25H10C10.4142 10.25 10.75 10.5858 10.75 11C10.75 11.4142 10.4142 11.75 10 11.75H7C6.58579 11.75 6.25 11.4142 6.25 11Z" fill="#4D4D4D"/>
                            </svg>
                              Печать
                          </button>
                          <button
                              className={'bg-white px-[12px] border-t-[1px] border-b-[1px]  border-[#E6E6E6] w-full py-[24px] flex flex-row items-center hover:bg-btn-icon hover:text-btn-txt'}>
                            <svg className={'eye-link svg-link mr-[14px]'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M11.998 9.16992C10.4322 9.16992 9.16797 10.4341 9.16797 11.9999C9.16797 13.5657 10.4322 14.8299 11.998 14.8299C13.5638 14.8299 14.828 13.5657 14.828 11.9999C14.828 10.4341 13.5638 9.16992 11.998 9.16992ZM7.66797 11.9999C7.66797 9.60571 9.60375 7.66992 11.998 7.66992C14.3922 7.66992 16.328 9.60571 16.328 11.9999C16.328 14.3941 14.3922 16.3299 11.998 16.3299C9.60375 16.3299 7.66797 14.3941 7.66797 11.9999Z" fill="#4D4D4D"/>
                              <path fillRule="evenodd" clipRule="evenodd" d="M2.25734 8.99669C4.64826 5.23833 8.15186 2.96973 11.9998 2.96973C15.848 2.96973 19.3517 5.23852 21.7427 8.99718C22.2876 9.85152 22.5348 10.9459 22.5348 11.9947C22.5348 13.0436 22.2876 14.1379 21.7427 14.9923C19.3517 18.7509 15.848 21.0197 11.9998 21.0197C8.1517 21.0197 4.64795 18.7509 2.25703 14.9923C1.71205 14.1379 1.46484 13.0436 1.46484 11.9947C1.46484 10.9457 1.71214 9.8511 2.25734 8.99669ZM11.9998 4.46973C8.78799 4.46973 5.71174 6.36093 3.52266 9.80227L3.52204 9.80325C3.16745 10.3588 2.96484 11.154 2.96484 11.9947C2.96484 12.8355 3.16745 13.6307 3.52204 14.1862L3.52266 14.1872C5.71174 17.6285 8.78799 19.5197 11.9998 19.5197C15.2117 19.5197 18.288 17.6285 20.477 14.1872L20.4777 14.1862C20.8322 13.6307 21.0348 12.8355 21.0348 11.9947C21.0348 11.154 20.8322 10.3588 20.4777 9.80325L20.477 9.80227C18.288 6.36093 15.2117 4.46973 11.9998 4.46973Z" fill="#4D4D4D"/>
                            </svg>
                            Предпросмотр
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={'bg-[#FAFAFA] bg-[#FFFFFF] items-start flex justify-center items-start h-[62px] pt-[10px]'} id="shared">
                      <div id={'links'} className={'flex justify-center items-start h-full'}>
                        Copyright © 2023 Redcat. All rights reserved
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
          {/*<div style={{display: "none"}}><Pdf ref={componentRef}/></div>*/}
        </>
    );}
};

export default Setting;