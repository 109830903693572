import React from 'react';
import {formatInternationalPhoneNumber} from "../helpers";

const Header = ({agent}: any) => {

    const phone = agent.phone !== null ? formatInternationalPhoneNumber(agent.phone, 'RU') : null;
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    return (
        <header className={'max-w-[1280px] w-full mx-auto py-[50px] max-md:py-[0px] max-md:my-[24px] flex flex-row justify-between items-center max-md:pt-[0px]'}>
            <div className={'max-md:hidden'}></div>
            <div className={'flex flex-row rounded-[12px] max-w-[700px] border-[1px] border-gray-150 max-md:px-[12px] max-md:py-[24px] p-[30px] gap-[30px] items-center justify-between max-md:w-full max-md:bg-[#F2F2F2] max-md:max-w-[100%]'}>
                {
                    agent.image ?
                        <img src={agent.image.links.preview} alt="agent-preview"
                             className={'max-md:hidden rounded-[10px] w-[90px] h-[90px] border border-[#E6E6E6]'}/>
                        : null
                }
                <div className={'flex flex-col leading-[1.75] items-start max-md:flex-row max-md:items-stretch max-md:w-full max-md:gap-[20px]'}>
                    <p className="name uppercase max-md:text-[12px] text-[14px] max-md:max-w-[120px] font-semibold max-md:text-[#4D4D4D] text-black leading-[20px] mb-[8px]">
                        {agent.first_name ? agent.first_name : null} {agent.last_name ? agent.last_name : null}
                    </p>
                    <div className={'flex flex-col items-start max-md:items-end max-md:w-full'}>
                        <a href={agent.phone ? `tel:${agent.phone}` : '#'} className="number text-[24px] max-md:font-semibold font-semibold max-md:text-[20px] max-md:text-[#151515] text-black  max-md:leading-[24px] leading-[32px] max-md:mb-[0px] mb-[8px]">
                            {phone ? phone : null}
                        </a>
                        <a href={agent.email ? `mailto:${agent.email}` : '#'} className="mail underline max-md:text-[#151515] text-black max-md:font-semibold font-semibold leading-[24px]">
                            {agent.email ? agent.email : null}
                        </a>
                    </div>
                </div>
                <div className={'max-md:hidden'}>
                    <ul className={'flex flex-col gap-[8px]'}>
                        {
                            agent.phone && agent.telegram &&
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src="/icons/telegram.svg" alt="telegram-icon"/>
                                <p className={"font-bold text-[16px]"}>Telegram</p>
                                <a className={'font-light flex flex-col gap-0 leading-[14px] underline-offset-2 underline md:font-normal'}
                                   href={`https://t.me/${agent.phone}`}>
                                    @{agent.telegram}
                                </a>
                            </li>
                        }
                        {
                            agent.whatsapp &&
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src="/icons/whatsapp.svg" alt="whatsapp-icon"/>
                                <p className={"font-bold text-[16px]"}>WhatsApp</p>
                                <a className={'font-light flex flex-col gap-0 leading-[14px] underline-offset-2 underline md:font-normal'}
                                   href={`https://wa.me/${agent.whatsapp}`}>
                                    {formatInternationalPhoneNumber(agent.whatsapp, 'RU')}
                                </a>
                            </li>
                        }
                        {
                            agent.viber &&
                            <li className={'flex flex-row items-center gap-[12px]'}>
                                <img src="/icons/viber.svg" alt="viber-icon"/>
                                <p className={"font-bold text-[16px]"}>Viber</p>
                                <a className={'font-light flex flex-col gap-0 leading-[14px] underline-offset-2 underline md:font-normal'}
                                   href={
                                       isMobile ?
                                           `https://viber.me/${agent.viber}` : `viber://chat?number=${agent.viber}`
                                   }
                                >
                                    {formatInternationalPhoneNumber(agent.viber, 'RU')}
                                </a>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;