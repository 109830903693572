import React from 'react';
// @ts-ignore
import scss from './loaderPDF.module.scss';
import doneNotify from './../../svg/notify/done-notify.svg'
import errorNotify from './../../svg/notify/error-notify.svg'

type LoaderNotify= {
    text: string;
    status: boolean;
}

const LoaderPDFNotify: React.FC<LoaderNotify> = ({text,status}) => {

    return (
        <div className={scss.notify}>
            <div className={scss.notifySub}>
                <div style={{
                    backgroundImage: `url("${status ? doneNotify : errorNotify}")`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: '30px',
                    height: '30px',
                }}>
                </div>
                <p className={scss.notify_text}>
                    {text}
                </p>
            </div>
        </div>
    );
};

export default LoaderPDFNotify;