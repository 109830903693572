export const keyDev ='113a4a91-2a17-4a1b-a43d-e85e90a349c0';//'113a4a91-2a17-4a1b-a43d-e85e90a349c0';
export const staticKeyDev='2393515c-da8a-4da8-b327-dac315447fe0'

export const defaultIcon = {
  iconLayout: 'default#image',
  iconImageHref: '/img/infrastrucure/zheka.svg',
  iconImageSize: [45, 45],
};

export const defaultIconMobile = {
  iconLayout: 'default#image',
  iconImageHref: '/img/infrastrucure/zheka.svg',
  iconImageSize: [45, 45],
  visible: window.matchMedia('(max-width: 768px)').matches === true ? true : false,
};

export const iconsMap = {
  infrastructurePoint: '/img/infrastrucure/infrastructurePoint.svg',
  infrastructureMetro: '/img/infrastrucure/infrastructureMetro.svg',
  infrastructureMetro2: '/img/infrastrucure/infrastructureMetro2.svg',
  infrastructureRJD: '/img/infrastrucure/infrastructureRJD.svg',
  infrastructureTrain: '/img/infrastrucure/infrastructureTrain.svg',
  infrastructureParking: '/img/infrastrucure/infrastructureParking.svg',
  infrastructureRoad: '/img/infrastrucure/infrastructureRoad.svg',
  infrastructurePark: '/img/infrastrucure/infrastructurePark.svg',
  infrastructureRiver: '/img/infrastrucure/infrastructureRiver.svg',
  infrastructureAmusementPark: '/img/infrastrucure/infrastructureAmusementPark.svg',
  infrastructureStore: '/img/infrastrucure/infrastructureAmusementPark.svg',
  infrastructureSupermarket: '/img/infrastrucure/infrastructureSupermarket.svg',
  infrastructureSport: '/img/infrastrucure/infrastructureSport.svg',
  infrastructureTheatre: '/img/infrastrucure/infrastructureTheatre.svg',
  infrastructureRailwayStation: '/img/infrastrucure/infrastructureRailwayStation.svg',
  infrastructureUniversity: '/img/infrastrucure/infrastructureUniversity.svg',
  infrastructureSchool: '/img/infrastrucure/infrastructureSchool.svg',
  infrastructureKindergarten: '/img/infrastrucure/infrastructureKindergarten.svg',
  infrastructureHospital: '/img/infrastrucure/infrastructureHospital.svg',
  infrastructureChurchle: '/img/infrastrucure/infrastructureChurchle.svg',
  infrastructureMosque: '/img/infrastrucure/infrastructureMosque.svg'
};

export const request = [
  {
    req: 'Школа',
    icon: iconsMap.infrastructureSchool,
    color: '#597373',
  },
  {
    req: 'Детский садик',
    icon: iconsMap.infrastructureKindergarten,
    color: '#32589A',
  },
  {
    req: 'Магазин',
    icon: iconsMap.infrastructureSupermarket,
    color: '#151515',
  },
  {
    req: 'Супер-маркет',
    icon: iconsMap.infrastructureSupermarket,
    color: '#151515',
  },
  {
    req: 'Фитнес',
    icon: iconsMap.infrastructureSport,
    color: '#666666',
  },
  {
    req: 'Парк',
    icon: iconsMap.infrastructurePark,
    color: '#2C3A3A',
  }
];