import React from 'react';
// @ts-ignore
import scss from './loader.module.scss';

type LoaderProps = {
    text: string;
    status?: string;
}

const Loader: React.FC<LoaderProps> = ({text, status}) => {
    return (
        <div className={status? scss.loaderContainerPdf : scss.loaderContainer}>
            <div className={scss.loader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <p>
                {text}
            </p>
        </div>
    );
};

export default Loader;