import { YMapLocation } from "@yandex/ymaps3-types/imperative/YMap";
import * as YMaps from "@yandex/ymaps3-types";
import {parsePhoneNumberFromString} from "libphonenumber-js"

export const features: YMaps.YMapFeatureProps[] = [
    {
        id: "1",
        style: {
            fillRule: "nonzero",
            fill: "var(--map-no-data-color)",
            fillOpacity: 0.6,
            stroke: [
                {
                    color: "var(--map-no-data-color)",
                    width: 5,
                },
            ],
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [37.8, 55.8],
                    [37.8, 55.75],
                    [37.9, 55.75],
                    [37.9, 55.8],
                ],
            ],
        },
        properties: { hint: "Polygon 1" },
    },
    {
        id: "2",
        style: {
            fillRule: "nonzero",
            fill: "var(--map-success-color)",
            fillOpacity: 0.6,
            stroke: [
                {
                    color: "var(--map-success-color)",
                    width: 5,
                },
            ],
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [37.9, 55.8],
                    [37.9, 55.75],
                    [38.0, 55.75],
                    [38.0, 55.8],
                ],
            ],
        },
        properties: { hint: "Polygon 2" },
    },
    {
        id: "3",
        style: {
            fillRule: "nonzero",
            fill: "var(--map-danger-color)",
            fillOpacity: 0.6,
            stroke: [
                {
                    color: "var(--map-danger-color)",
                    width: 5,
                },
            ],
        },
        geometry: {
            type: "Polygon",
            coordinates: [
                [
                    [38.0, 55.8],
                    [38.0, 55.75],
                    [38.1, 55.75],
                    [38.1, 55.8],
                ],
            ],
        },
        properties: { hint: "Polygon 3" },
    },
];

export const location: YMapLocation = { center: [37.95, 55.65], zoom: 10 };
// apikey is only for codesandbox.io, xk3d74.csb.app and wxn9cy.csb.app
export const apiKey = "30888d84-d5cc-4242-b612-e9119a293983";

export function formatInternationalPhoneNumber(phoneNumber:any, countryCode:any) {
    const phoneNumberInstance = parsePhoneNumberFromString(phoneNumber, countryCode);
    if (!phoneNumberInstance) return phoneNumber;
    return phoneNumberInstance.formatNational();
}

export const formatPrice = (value: number, locale = 'ru-RU', currency = 'RUB') => {
    let formattedValue = Number(value).toFixed(2);

    if (formattedValue.endsWith('.00')) {
        formattedValue = parseInt(formattedValue, 10).toString();
    }

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
    }).format(Number(formattedValue)).replace('₽','');
};

export function getQuarter(dateString: any) {
    const date = new Date(dateString);
    const month = date.getMonth();
    const quarter = Math.floor(month / 3) + 1;
    return quarter;
}

export function convertToRoman(number:number){
    return [
        { value: 1000, char: 'M' },
        { value: 900, char: 'CM' },
        { value: 500, char: 'D' },
        { value: 400, char: 'CD' },
        { value: 100, char: 'C' },
        { value: 90, char: 'XC' },
        { value: 50, char: 'L' },
        { value: 40, char: 'XL' },
        { value: 10, char: 'X' },
        { value: 9, char: 'IX' },
        { value: 5, char: 'V' },
        { value: 4, char: 'IV' },
        { value: 1, char: 'I' }
    ].reduce((result, currentValue) => {
        while (number >= currentValue.value) {
            result += currentValue.char;
            number -= currentValue.value;
        }

        return result;
    }, '');
}

export function getNoun(number:number, one:string, two:string, five:string) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
}