import React, {useEffect, useRef, useState} from "react";
import Pdf from "./pdf";
import {Link} from "react-router-dom";
import  {jsPDF} from 'jspdf'
import html2canvas from "html2canvas";
import LoaderPDF from "../ui/loaderPDF";
// @ts-ignore
import scss from './loadingPagePdf.module.scss'
import imagePDFReady from './../../svg/pdf/pdf-loading-image.svg'
import LoaderPDFNotify from "../ui/loaderPDFnotify";

const PdfLoadingPage = ({data}) => {
    const [pdf,setPDF] = useState<any>()
    const [totalPages,setTotalPages] = useState<number>(0)
    const [pdfReady, setPdfReady] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [error,setError] = useState(false)
    const componentRef = useRef<HTMLDivElement>(null);
    const [startLoading, setStartLoading] = useState(false);

    const house = data.housing_complex || data.apartment.housing_complex;

    const generatePdf = async () => {
        setLoading(true);
        setLoadingProgress(10);

        try {
            if (componentRef.current) {
                const pdf = new jsPDF({ unit: "mm", format: [297, 210], orientation: "landscape" });
                pdf.setFillColor(255, 255, 255);

                const slides = componentRef.current.children;
                const totalSlides = slides.length;
                setTotalPages(totalSlides);

                for (let i = 0; i < totalSlides; i++) {
                    const slide = slides[i] as HTMLElement;

                    const canvas = await html2canvas(slide, { scale: 2, useCORS: true });
                    const imgData = canvas.toDataURL('image/png', 0.8);

                    const imgWidth = pdf.internal.pageSize.getWidth();
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;

                    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');

                    if (i < totalSlides - 1) {
                        pdf.addPage();
                    }

                    setLoadingProgress(((i + 1) / totalSlides) * 100);
                }

                setPDF(pdf);
            }
        } catch (error) {
            console.error('Ошибка при создании PDF:', error);
            setError(true);
        }
    };

    const downloadPdf = async () => {
        await generatePdf();
    };

    useEffect(() => {
        downloadPdf()
    }, []);

    useEffect(() => {
        if(loadingProgress === 100){
            setTimeout(() => {
                setLoading(false);
                setPdfReady(true)
                setStartLoading(true)
            }, totalPages * 1000);
        }
    }, [loadingProgress]);

    return (
        <>
            {
                !pdfReady && loading && !error &&
                <div className={scss.containerLoading}>
                    <LoaderPDF loadingProgress={loadingProgress} totalPages={totalPages}/>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Идёт процесс формирования PDF файла</p>
                        <p className={scss.loaderPDFdesc_subTitle}>Скоро начнётся загрузка файла презентации</p>
                    </div>
                    <Link className={scss.loaderPDFBtn} to={`/${window.location.search}`}>
                        Вернуться назад
                    </Link>
                </div>
            }
            {
                error &&
                <div className={scss.containerLoading}>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Произошла ошибка при загрузке документа</p>
                    </div>
                    <Link className={scss.loaderPDFBtn} to={`/${window.location.search}`}>
                        Вернуться назад
                    </Link>
                </div>
            }
            {
                pdfReady && !error &&
                <div className={scss.containerLoading}>
                    <div style={{
                        backgroundImage: `url(${imagePDFReady})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        width: "284.99px",
                        height: "261.54px",
                        marginBottom: "67px"
                    }}></div>
                    <div className={scss.loaderPDFDesc}>
                        <p className={scss.loaderPDFdesc_title}>Формирование PDF файла завершено!</p>
                        <p className={scss.loaderPDFdesc_subTitle}>Скачать файл презентации можно по кнопке ниже</p>
                    </div>
                    <div className={scss.loaderButtons}>
                        <Link className={scss.loaderPDFBtn_prev} to={`/${window.location.search}`}>
                            Вернуться назад
                        </Link>
                        <button className={scss.loaderPDFBtn_load}
                        onClick={() => {
                            pdf.save(`${house.name}.pdf`);
                        }}>
                            Скачать файл
                        </button>
                    </div>
                </div>
            }
            {
                !error &&
                <div style={{ opacity: 0, pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                    <Pdf ref={componentRef} data={data} status={'pdf'}/>
                </div>
                // <Pdf ref={componentRef} data={data} status={'pdf'}/>
            }
            {
                startLoading &&
                    <LoaderPDFNotify text={'Готово! Файл презентации загружен'} status={true} />
            }
            {
                error &&
                    <LoaderPDFNotify
                        text={'Что-то пошло не так! Загрузка приостановлена'}
                        status={false}
                    />
            }
        </>
    );
};

export default PdfLoadingPage;