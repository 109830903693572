import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Использует localStorage по умолчанию
import dataSlice, {setData} from "./slices/dataSlice";
import dataStatic from "./slices/dataStatic";
import dataPDFSetting from "./slices/dataPDFSettings";
import scrollSlice from "./slices/scrollSlice";
import modalSettings from "./slices/modalSettings";
import skipStatusSlice from  "./slices/observerCheck"
import dataReadyIcons from "./slices/staticReady"
import inViewSlice from "./slices/inViewsSlice"

// Объединяем редьюсеры и оборачиваем их в persistReducer
const rootReducer = combineReducers({
    dataSlice,
    dataStatic,
    dataPDFSetting,
    scrollSlice,
    modalSettings,
    skipStatusSlice,
    dataReadyIcons,
    inViewSlice,
});

// Тип состояния всего хранилища
type RootState = ReturnType<typeof rootReducer>;

// Функция для сохранения состояния в localStorage
function saveStateToLocalStorage(state: RootState) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('reduxState', serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
}

// Функция для загрузки состояния из localStorage
function loadStateFromLocalStorage(): RootState | undefined {
    try {
        const serializedState = localStorage.getItem('reduxState');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState) as RootState;
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
}

// Загружаем состояние из localStorage
const preloadedState = loadStateFromLocalStorage();

// Создаем store с типизацией
const store = configureStore({
    reducer: rootReducer,
    preloadedState, // Предзагрузка состояния
});

// Сохраняем состояние при каждом изменении
store.subscribe(() => {
    saveStateToLocalStorage(store.getState());
});

export default store;

// Экспортируем типы для корневого состояния и диспатча
export type { RootState }; // Экспортируем определение RootState
export type AppDispatch = typeof store.dispatch; // Тип диспатча