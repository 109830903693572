import React from 'react';
// @ts-ignore
import scss from './loaderPDF.module.scss';

type LoaderProps = {
    loadingProgress: number;
    totalPages: number;
}

const LoaderPDF: React.FC<LoaderProps> = ({loadingProgress,totalPages}) => {

    return (
            <div className={scss.loaderPDFProgressBar} style={{
                marginBottom: "130px"
            }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent:'start'
                }}>
                    <div className={scss.loaderPDFProgressBar_line}
                         style={{
                             width: `${loadingProgress}%`,
                             transition: `width ${totalPages}s ease-in-out`
                         }}
                    ></div>
                </div>
            </div>
    );
};

export default LoaderPDF;