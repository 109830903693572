import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectFade, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import question from "../../images/no-data.png";

const SectionSlider = ({name, images, hc}: any) => {
    function getQuarter(dateString: any) {
        const date = new Date(dateString);
        const month = date.getMonth();
        const quarter = Math.floor(month / 3) + 1;
        return quarter;
    }

    const year = hc.deadline ? new Date(hc.deadline).getFullYear() : null;

    return (
        <section className={'flex flex-col max-md:gap-[16px] gap-[30px]'}>
            <h1 className={'text-[60px] leading-[60px] max-md:text-[24px] mb-[30px] max-md:mb-[16px] max-md:leading-[44px]'}>{name}</h1>
            <Swiper
                navigation={true}
                height={560}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{
                    type: 'fraction',
                }}
                grabCursor={true}
                modules={[EffectFade, Pagination, Navigation]}
            >
                {images?.map((image: any) => (
                    <SwiperSlide key={image.id} className={'h-full w-full'}>
                        <div className={'bg-cover min-w-full h-[47rem]'}
                             style={{
                                 backgroundImage: `url(${image.links.large ? image.links.large : question})`,
                                 backgroundSize: "cover",
                                 backgroundRepeat: "no-repeat",
                                 backgroundPosition: "center center",
                             }}>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {
                !hc.class && !hc.type && !hc.deadline && !hc.district ? null :
                <div className={hc.class && hc.type && hc.deadline && hc.district ?
                    'flex flex-row max-md:flex-col border-[#E6E6E6] border-[1px] rounded-[24px] p-[30px] justify-between bg-[#FAFAFA] gap-[6px]' :
                    'flex flex-row max-md:flex-col border-[#E6E6E6] border-[1px] rounded-[24px] p-[30px] justify-start max-md:gap-[0px] gap-[130px] bg-[#FAFAFA]' }>
                    {
                        hc.class ?
                            <div className={'text-[18px] leading-[26px] max-md:grid max-md:grid-cols-2 max-md:font-semibold'}>
                                <p>Класс ЖК</p>
                                <p className={'font-light'}>{hc.class}</p>
                            </div> : null
                    }
                    {
                        hc.estate_types[0] && hc.estate_types[0].name ?
                            <div className={'text-[18px] leading-[26px] max-md:grid max-md:grid-cols-2 max-md:font-semibold'}>
                                <p>Тип дома</p>
                                <p className={'font-light'}>{hc.estate_types[0].name}</p>
                            </div> : null
                    }
                    {
                        hc.deadline ?
                            <div className={'text-[18px] leading-[26px] max-md:grid max-md:grid-cols-2 max-md:font-semibold'}>
                                <p>Срок сдачи</p>
                                <p className={'font-light'}>{getQuarter(hc.deadline)} квартал {year} года</p>
                            </div> : null
                    }
                    {
                        hc.district ?
                            <div className={'text-[18px] leading-[26px] max-md:grid max-md:grid-cols-2 max-md:font-semibold'}>
                                <p>Район</p>
                                <p className={'font-light'}>{hc.district.name}</p>
                            </div> : null
                    }
                </div>
            }
        </section>
    );
};

export default SectionSlider;